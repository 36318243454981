import { Viale } from "./viale";
import { Zona } from "./zona";

export interface Struttura {
    id?: string;
    tipoStruttura?: number;
    numero?: string;
    denominazione?: string;
    monoStecca: boolean;
    zonaId: string;
    zona?: Zona,
    vialeId: string;
    viale?: Viale,
    strutturaSemplice: boolean;
    porta: string;
    concessioneSuoloNumero?: string;
    concessioneSuoloNome?: string;
    concessioneSuoloData: string;
    concessioneEdiliziaNumero?: string;
    concessioneEdiliziaNome?: string;
    concessioneEdiliziaData: string;
    note: string;
    tenantId?: string;
    dateOfInsert?: string;
    isValid?: boolean;

    strutturaImage?: string;

    concessionari?: any;
    steccheStruttura?: any;
    allegati?: any;
}

export const defaultStruttura: Struttura = {
    monoStecca: false,
    zonaId: '',
    vialeId: '',
    strutturaSemplice: true,
    porta: '',
    concessioneSuoloData: '',
    concessioneEdiliziaData: '',
    note: '',
}
import { Struttura } from "./struttura";

export interface Stecca {
    id?: string;
    codice: string;
    denominazione: string;
    struttura?: Struttura;
    strutturaId?: string;
    numRighe: number;
    numColonne: number;

    numPostiLiberi?: number;
    numPostiAventiDiritto?: number;
    numPostiConcessionari?: number;
    numPostiSalme?: number;
    numPostiReferenti?: number;
    numeroIniziale?: number;

    guadagnabile?: number;
    guadagnato?: number;

    infoPostiLiberi?: Array<string>;
    infoPostiAventiDiritto?: Array<string>;
    infoPostiConcessionari?: Array<string>;
    infoPostiReferenti?: Array<string>;
    infoPostiSalme?: Array<string>;

    steccaImage?: string;

    dateOfInsert?: string;
    isValid?: boolean;
}

export const defaultStecca: Stecca = {
    codice: '',
    denominazione: '',
    numRighe: 1,
    numColonne: 1,
    numeroIniziale: 1
}
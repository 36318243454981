import { Avatar, Box, Button, Skeleton } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import defaultImage from '../assets/images/default.png';
import { composeNotistackMessage } from 'utils/common';

interface ImageDropzoneProps {
    imageBase64: string;
    handleUpdateImage: (newImageBase64: string) => void;
    checkImageValidity?: (base64: string) => Promise<boolean>;
    imageValidityErrorMessage?: string;
}

const ImageDropzone: FC<ImageDropzoneProps> = (props) => {
    const { imageBase64, handleUpdateImage, checkImageValidity, imageValidityErrorMessage } = props;

    const logoInputRef = useRef<HTMLInputElement>(null);

    const [loading, setLoading] = useState<boolean>(true);
    const [logo, setLogo] = useState<string>('');

    const handleClickUpload = () => {
        if (logoInputRef.current) {
            logoInputRef.current.click();
        }
    }

    useEffect(() => {
        setLogo(imageBase64 !== '' ? imageBase64 : defaultImage);

        setLoading(false);
    }, [imageBase64])

    const handleFileInputChange = async (e: any) => {
        if (e.target.files[0] !== undefined) {
            var fileReader: FileReader = new FileReader();
            fileReader.readAsDataURL(e.target.files[0]);
            fileReader.onload = async () => {
                let newFileBase64: string = fileReader.result as string;

                if ((checkImageValidity !== undefined && await checkImageValidity(newFileBase64) === true) ||
                    checkImageValidity === undefined) {
                    setLogo(newFileBase64);
                    handleUpdateImage(newFileBase64);
                } else {
                    composeNotistackMessage(imageValidityErrorMessage!, 'error');
                }
            }
        }
    }

    const handleRemoveImage = () => {
        setLogo(defaultImage);
        handleUpdateImage('');
    }

    return (
        <Box sx={{ flexDirection: 'column' }}>
            {
                loading ?
                    <>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
                            <Skeleton variant="rounded" width={400} height={300} />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', }}>
                            <Skeleton variant="rounded" width={125} height={50} />
                        </Box>
                    </> :
                    <>
                        <Box sx={{ display: 'flex', justifyContent: 'center', }}>
                            <input
                                type="file"
                                ref={logoInputRef}
                                style={{ display: 'none' }}
                                onChange={handleFileInputChange}
                            />
                            <Avatar
                                src={logo}
                                onClick={handleClickUpload}
                                sx={{
                                    width: "25%",
                                    height: "25%",
                                    borderRadius: "10px",
                                    mb: 1,
                                    transition: 'opacity 0.3s',
                                    ':hover': {
                                        opacity: 0.7,
                                        cursor: 'pointer'
                                    },
                                    backgroundColor: 'transparent'
                                }}
                            />
                        </Box>
                        {
                            imageBase64 !== '' && (
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        onClick={handleRemoveImage}
                                    >
                                        <FormattedMessage id="removeImage" />
                                    </Button>
                                </Box>
                            )
                        }
                    </>
            }
        </Box>
    );
}

export default ImageDropzone;
import { Close, DeleteTwoTone } from "@mui/icons-material";
import { Box, Button, Card, CardContent, CardHeader, Collapse, Dialog, DialogActions, DialogTitle, Divider, Grid, IconButton, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { composeNotistackMessage, getFormValue, getShortDate, handleDownloadFile, isNotNullOrUndefined } from "utils/common";
import { number, object, string } from "yup";
import { handleUpsertErrors } from "utils/validation";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Allegato } from "types/allegato";
import VisuallyHiddenInput from "ui-component/VisuallyHiddenInput";
import CardContentLoader from "ui-component/CardContentLoader";
import React from "react";
import { Posto } from "types/posto";
import { PostoService } from "services/PostoServices";
import UsersList from "pages/Utenti/UsersList";
import { Utente } from "types/utente";
import AlternateTableRow from "ui-component/AlternateTableRow";
import PurpleStyledTableCell from "ui-component/PurpleStyledTableCell";
import DecuiusList from "pages/Decuius/DecuiusList";
import { Decuius } from "types/decuius";
import { StatoDecuius } from "types/enums/StatoDecuius";
import InboxIcon from '@mui/icons-material/Inbox';
import Coffin from "ui-component/icons/Coffin";
import DownloadIcon from '@mui/icons-material/Download';
import ArrowOutwardSharpIcon from '@mui/icons-material/ArrowOutwardSharp';
import TableRowsLoader from "ui-component/TableRowsLoader";
import DialogDeleteElement from "ui-component/DialogDeleteElement";
import { StatoPosto } from "types/enums/StatoPosto";
import { UserService } from "services/UserServices";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BackdropLoader from "ui-component/BackdropLoader";
import { IconFiles } from "@tabler/icons";
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ImageDropzone from "ui-component/ImageDropzone";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const PostoUpdateTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`posto-tabpanel-${index}`}
            aria-labelledby={`posto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>{children}</>
            )}
        </div>
    );
}

const PostoUpdate = () => {
    const intl = useIntl();

    const postoService = new PostoService();
    const userService = new UserService();

    const [posto, setPosto] = useState<Posto | undefined>(undefined);

    let postoValidationSchema = object({
        numero: string().required('numberRequired'),
        ordinamento: number().required().min(1, 'sortingMinOne').typeError('sortingMustBeNumber'),
        valoreEconomico: number().min(0, 'economicValueMinZero').typeError('economicValueMustBeNumber'),
        note: string(),
    });

    const [tabIndex, setTabIndex] = useState<number>(0);

    const [files, setFiles] = useState<Array<Allegato>>([]);

    const navigate = useNavigate();

    const [isPageLoading, setIsPageLoading] = useState<boolean>(false);

    const [openAssignUserModal, setOpenAssignUserModal] = useState<boolean>(false);

    const [assignedUser, setAssignedUser] = useState<Utente | null>(null);

    const [assignedDecuius, setAssignedDecuius] = useState<Decuius | null>(null);
    const [assignedDecuiusBoxes, setAssignedDecuiusBoxes] = useState<Array<Decuius>>([]);

    const [assignedDecuiusBoxesIds, setAssignedDecuiusBoxesIds] = useState<Array<string>>([]);

    const [openAssignCorpseModal, setOpenAssignCorpseModal] = useState<boolean>(false);
    const [openAssignBoxesModal, setOpenAssignBoxesModal] = useState<boolean>(false);

    const [isBoxesLoading, setIsBoxesLoading] = useState<boolean>(false);

    const [isLoadingFileUpload, setIsLoadingFileUpload] = useState<boolean>(false);

    useEffect(() => {
        setIsPageLoading(true);

        (async () => {
            let postoId: string = window.location.href.split('postoId=')[1].split('&')[0] ?? '';

            let retrievedPosto: Posto = await postoService.GetPostoById(postoId);
            setPosto(retrievedPosto);

            setPlaceImageBase64(retrievedPosto.postoImage ?? '');

            let retrievedAllegati: Array<Allegato> = await postoService.GetPostoAllegati(retrievedPosto.id);
            setFiles(retrievedAllegati);

            await handleUserDecuiusToAssign(retrievedPosto, postoId);

            setIsPageLoading(false);
        })();
    }, [])

    const handleUserDecuiusToAssign = async (retrievedPosto: Posto, postoId: string) => {
        let userToAssignId: string = window.location.href.split('utenteId=')[1] ?? undefined;
        if (userToAssignId !== undefined && retrievedPosto?.aventeDiritto == null && retrievedPosto?.concessionario == null && retrievedPosto?.referente == null) {
            setUserToAssignId(userToAssignId);
            setTabIndex(1);
            setChooseUserTypeModalOpen(true);
        } else {
            let retrievedAssignedUser: Utente | null = await postoService.GetPostoAssignedUtente(retrievedPosto.id);
            setAssignedUser(retrievedAssignedUser);
        }

        let decuiusToAssignId: string = window.location.href.split('decuiusCorpseId=')[1] ?? undefined;
        if (decuiusToAssignId !== undefined) {
            assignDecuius(decuiusToAssignId, postoId);
            setTabIndex(2);
        } else {
            retrieveAssignedDecuius(retrievedPosto.id);
        }

        let decuiusBoxToAssignId: string = window.location.href.split('decuiusBoxId=')[1] ?? undefined;
        if (decuiusBoxToAssignId !== undefined) {
            assignDecuiusBox(decuiusBoxToAssignId, postoId);
            setTabIndex(2);
        }
        retrieveAssignedDecuiusBoxes(retrievedPosto.id);
    }

    const assignDecuius = async (decuiusId: string, postoId: string | undefined = undefined) => {
        try {
            await postoService.AssignDecuiusToPosto({
                postoId: postoId ?? posto!.id,
                decuiusId: decuiusId,
                statoDecuius: StatoDecuius.Salma
            });

            retrieveAssignedDecuius(postoId ?? posto!.id);
            setOpenAssignCorpseModal(false);

            composeNotistackMessage(intl.formatMessage({ id: 'decuiusAssignedSuccessfully' }), 'success');
        } catch (e: any) {
            composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
        }
    }

    const assignDecuiusBox = async (decuiusId: string, postoId: string | undefined = undefined) => {
        try {
            setIsBoxesLoading(true);

            await postoService.AssignDecuiusToPosto({
                postoId: postoId ?? posto!.id,
                decuiusId: decuiusId,
                statoDecuius: StatoDecuius.Resti
            });

            retrieveAssignedDecuiusBoxes(postoId ?? posto!.id);
            composeNotistackMessage(intl.formatMessage({ id: 'decuiusAssignedSuccessfully' }), 'success');
        } catch (e: any) {
            composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
        }
    }

    const handleChange = (e: any) => {
        let { name, value } = getFormValue(e);
        setPosto((currentFormData: any) => ({ ...currentFormData, [name]: value }));
    }

    const postoUpsert = async () => {
        try {
            await postoValidationSchema.validate(posto, { abortEarly: false });

            await postoService.UpsertPosto(posto!);

            composeNotistackMessage(intl.formatMessage({ id: 'placeUpdatedSuccessfully' }), 'success');
        } catch (validationErrors: any) {
            handleUpsertErrors(validationErrors.inner, intl);
        }
    }

    const a11yProps = (index: number) => {
        return {
            id: `posto-tab-${index}`,
            'aria-controls': `posto-tabpanel-${index}`,
        };
    }

    const addDocument = async (newFileName: string, newFileBase64: string) => {
        setIsLoadingFileUpload(true);

        try {
            await postoService.InsertAllegatoToPosto({
                postoId: posto!.id,
                nomeFile: newFileName,
                allegatoBase64: newFileBase64
            });

            let retrievedAllegati: Array<Allegato> = await postoService.GetPostoAllegati(posto!.id);

            setFiles(retrievedAllegati);
        } catch (e: any) {
            composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
        } finally {
            setIsLoadingFileUpload(false);
        }
    }

    const handleUploadFile = async (e: any) => {
        var fileReader: FileReader = new FileReader();
        let newFileName = e.target.files[0].name;
        fileReader.readAsDataURL(e.target.files[0]);
        fileReader.onload = async () => {
            let newFileBase64: string = fileReader.result as string;
            await addDocument(newFileName, newFileBase64);
        }
    }

    const handleDeleteFile = async (allegatoId: string) => {
        await postoService.DeleteAllegatoFromPosto(allegatoId);
        setFiles(files.filter((file) => file.id !== allegatoId));
    }

    const handleAddUser = () => {
        let postoId: string = posto!.id;
        navigate(`/users/create?postoId=${postoId}`);
    }

    const handleAddDecuius = (statoDecuius: StatoDecuius) => {
        let postoId: string = posto!.id;
        navigate(`/decuius/create?postoId=${postoId}&decuiusType=${statoDecuius}`);
    }

    const chooseUser = async (userId: string) => {
        setUserToAssignId(userId);
        setChooseUserTypeModalOpen(true);
    }

    const [userToAssignId, setUserToAssignId] = useState<string>('');
    const [chooseUserTypeModalOpen, setChooseUserTypeModalOpen] = useState<boolean>(false);

    const assignUser = async (statoPosto: number) => {
        await postoService.AssignUserToPosto({
            postoId: posto!.id,
            utenteId: userToAssignId,
            statoPosto: statoPosto
        });

        let retrievedAssignedUser: Utente | null = await postoService.GetPostoAssignedUtente(posto!.id);
        setAssignedUser(retrievedAssignedUser);
        retrievePosto(posto!.id);
        setOpenAssignUserModal(false);
    }

    const retrieveAssignedDecuius = async (postoId: string) => {
        let retrievedAssignedDecuius: Decuius | null = await postoService.GetPostoAssignedDecuius(postoId);
        setAssignedDecuius(retrievedAssignedDecuius);

        setIsPageLoading(false);
    }

    const retrieveAssignedDecuiusBoxes = async (postoId: string) => {
        let retrievedAssignedDecuiusBoxes: Array<Decuius> = await postoService.GetPostoAssignedDecuiusBoxes(postoId);
        setAssignedDecuiusBoxes(retrievedAssignedDecuiusBoxes);

        setIsPageLoading(false);
        setIsBoxesLoading(false);
    }

    const removeAssignedUserFromPosto = async () => {
        await postoService.RemoveUtenteFromPosto(posto!.id);

        setAssignedUser(null);

        retrievePosto(posto!.id);
    }

    const removeAssignedDecuiusFromPosto = async () => {
        await postoService.RemoveDecuiusFromPosto(assignedDecuius!.id!);

        setAssignedDecuius(null);
    }

    const removeAssignedDecuiusBoxFromPosto = (decuiusId: string) => {
        setIsBoxesLoading(true);

        (async () => {
            await postoService.RemoveDecuiusFromPosto(decuiusId);
            setAssignedDecuiusBoxes(assignedDecuiusBoxes.filter((decuius) => decuius.id !== decuiusId));

            setIsBoxesLoading(false);
        })()
    }

    const retrievePosto = async (postoId: string) => {
        let retrievedPosto: Posto = await postoService.GetPostoById(postoId);
        setPosto(retrievedPosto);
    }

    useEffect(() => {
        setAssignedDecuiusBoxesIds(assignedDecuiusBoxes.map((assignedDecuiusBox) => assignedDecuiusBox.id!));
    }, [assignedDecuiusBoxes])

    const changeDecuiusStatus = async (decuiusId: string, statoDecuius: StatoDecuius) => {
        await postoService.ChangeDecuiusStatus({
            postoId: posto!.id,
            decuiusId: decuiusId,
            statoDecuius: statoDecuius
        });

        setIsPageLoading(true);
        retrieveAssignedDecuius(posto!.id);
        retrieveAssignedDecuiusBoxes(posto!.id);
    }

    const changePlaceVisibility = async () => {
        try {
            await postoService.ChangePlaceVisibility(posto!.id);

            setPosto((prevData: any) => ({ ...prevData, visibile: !posto?.visibile }));
        } catch (e: any) {
            composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
        }
    }

    const [removeAttachmentDialogOpen, setRemoveAttachmentDialogOpen] = useState<boolean>(false);
    const [removeDecuiusDialogOpen, setRemoveDecuiusDialogOpen] = useState<boolean>(false);
    const [removeDecuiusBoxDialogOpen, setRemoveDecuiusBoxDialogOpen] = useState<boolean>(false);
    const [removeDealerEntitledDialogOpen, setRemoveDealerEntitledDialogOpen] = useState<boolean>(false);

    const [entityToRemoveData, setEntityToRemoveData] = useState<{ id: string, name: string } | undefined>();

    useEffect(() => {
        switch (posto?.statoPosto) {
            case StatoPosto.Concessionario:
                setHeaderLabelType("dealer");
                break;
            case StatoPosto.AventeDiritto:
                setHeaderLabelType("entitled");
                break;
            case StatoPosto.Referente:
                setHeaderLabelType("referent");
                break;
            default:
                setHeaderLabelType("dealer");
                break;
        }
    }, [posto?.statoPosto])

    const [headerLabelType, setHeaderLabelType] = useState<string>('');

    const makeUserDecuius = async () => {
        if (isNotNullOrUndefined(assignedDecuius)) {
            composeNotistackMessage(intl.formatMessage({ id: 'corpseAlreadyPresent' }), 'error');
        } else {
            try {
                if (isNotNullOrUndefined(assignedUser?.decuiusId)) {
                    await assignDecuius(assignedUser!.decuiusId!, posto!.id!)
                    setTabIndex(2);
                } else {
                    const newDecuiusId: string = await userService.MakeUserDecuius(assignedUser!.id!);

                    navigate(`/decuius/edit?decuiusId=${newDecuiusId}&postoId=${posto!.id}&decuiusType=${StatoDecuius.Salma}`);
                }
            } catch (e: any) {
                composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
            }
        }
    }

    const downloadAllegato = async (allegatoId: string, allegatoName: string) => {
        const allegatoBase64: string = await postoService.DownloadPostoAllegato(allegatoId);
        handleDownloadFile(allegatoBase64, allegatoName);
    }

    const [fileInEditId, setFileInEditId] = useState<string>('');

    const [newFileName, setNewFileName] = useState<string>('');

    const handleCloseEditName = () => {
        setFileInEditId('');
        setNewFileName('');
    }

    const handleConfirmEditName = async () => {
        setIsPageLoading(true);

        try {
            await postoService.RenameAllegatoPosto({
                allegatoId: fileInEditId,
                newAllegatoName: newFileName
            });

            composeNotistackMessage(intl.formatMessage({ id: 'fileRenamedSuccessfully' }), 'success');
            handleCloseEditName();

            let retrievedAllegati: Array<Allegato> = await postoService.GetPostoAllegati(posto!.id);
            setFiles(retrievedAllegati);

        } catch (e: any) {
            composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
        } finally {
            setIsPageLoading(false);
        }
    }

    const [placeImageBase64, setPlaceImageBase64] = useState<string>('');

    const handleUpdateImage = async (base64: string) => {
        setPlaceImageBase64(base64);
        setPosto((prevData: any) => ({ ...prevData, postoImage: base64 }));
    }

    return (
        <Card>
            <BackdropLoader open={isLoadingFileUpload} />

            <CardHeader title={
                <Typography variant="h3">
                    <FormattedMessage id="structure" />: {posto?.steccaProvenienza?.struttura?.denominazione}
                </Typography>
            } />
            <Divider />
            <Tabs value={tabIndex} onChange={(event: any, newTabIndex: number) => { setTabIndex(newTabIndex) }} aria-label="Places tabs" variant="scrollable" scrollButtons="auto">
                <Tab
                    label={<FormattedMessage id="generalData" />}
                    {...a11yProps(0)}
                />
                <Tab
                    label={<FormattedMessage id="header" />}
                    {...a11yProps(1)}
                />
                <Tab
                    label={<FormattedMessage id="corpseAndBoxes" />}
                    {...a11yProps(2)}
                />
                <Tab
                    label={<FormattedMessage id="attachments" />}
                    {...a11yProps(3)}
                />
            </Tabs>
            {
                isPageLoading ?
                    <CardContentLoader /> :
                    (
                        <CardContent>
                            {/* TODO: Spostare ogni panel in un component a parte o capire come fare meglio. Sicuramente da sistemare */}
                            <PostoUpdateTabPanel value={tabIndex} index={0}>
                                <Box sx={{ marginBottom: '15px' }}>
                                    <ImageDropzone
                                        imageBase64={placeImageBase64}
                                        handleUpdateImage={handleUpdateImage}
                                    />
                                </Box>
                                <Grid container spacing={1}>
                                    <Grid
                                        item
                                        lg={4}
                                        md={4}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            size='small'
                                            label={<FormattedMessage id="number" />}
                                            name="numero"
                                            onChange={handleChange}
                                            value={posto?.numero}
                                            required
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        lg={4}
                                        md={4}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            size='small'
                                            label={<FormattedMessage id="sortingNumber" />}
                                            name="ordinamento"
                                            onChange={handleChange}
                                            value={posto?.ordinamento}
                                            required
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        lg={4}
                                        md={4}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            size='small'
                                            label={<FormattedMessage id="economicValue" />}
                                            name="valoreEconomico"
                                            onChange={handleChange}
                                            value={posto?.valoreEconomico}
                                            required
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        lg={12}
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            size='small'
                                            label={<FormattedMessage id="notes" />}
                                            name="note"
                                            inputProps={{
                                                style: {
                                                    height: 50
                                                }
                                            }}
                                            onChange={handleChange}
                                            value={posto?.note}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item
                                        xs={12}
                                        md={6}
                                        lg={6}
                                    >
                                        <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-start', gap: 2 }}>
                                            <Button
                                                color={posto?.visibile ? "error" : "success"}
                                                type="submit"
                                                variant="contained"
                                                onClick={changePlaceVisibility}
                                                startIcon={posto?.visibile ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                            >
                                                <FormattedMessage id={posto?.visibile ? "makeNotVisible" : "makeVisible"} />
                                            </Button>
                                            <Button
                                                onClick={() => navigate(`/structures/edit?strutturaId=${posto?.steccaProvenienza?.struttura?.id}`)}
                                                startIcon={<ArrowOutwardSharpIcon fontSize="small" sx={{ fontSize: '13px' }} />}
                                                variant="contained"
                                            >
                                                <FormattedMessage id="backToStructure" />
                                            </Button>
                                        </Box>
                                    </Grid>
                                    <Grid item
                                        xs={12}
                                        md={6}
                                        lg={6}
                                    >

                                        <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}>
                                            <Button
                                                color="primary"
                                                type="submit"
                                                variant="contained"
                                                onClick={postoUpsert}
                                                startIcon={<SaveIcon />}
                                            >
                                                <FormattedMessage id="save" />
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>

                            </PostoUpdateTabPanel>
                            <PostoUpdateTabPanel value={tabIndex} index={1}>
                                <Dialog
                                    open={chooseUserTypeModalOpen}
                                >
                                    <DialogTitle id="choose-place-user-type">
                                        <FormattedMessage id="choosePlaceUserTypeTitle" />
                                        <IconButton aria-label="close" onClick={() => setChooseUserTypeModalOpen(false)} style={{ position: 'absolute', right: '5px', top: '4px' }}>
                                            <Close />
                                        </IconButton>
                                    </DialogTitle>
                                    <DialogActions>
                                        <Button variant="contained" onClick={() => {
                                            setChooseUserTypeModalOpen(false);
                                            assignUser(StatoPosto.Concessionario);
                                        }} color="secondary"><FormattedMessage id="asDealer" /></Button>

                                        <Button variant="contained" onClick={() => {
                                            setChooseUserTypeModalOpen(false);
                                            assignUser(StatoPosto.AventeDiritto);
                                        }} color="warning"><FormattedMessage id="asEntitled" /></Button>

                                        <Button variant="contained" onClick={() => {
                                            setChooseUserTypeModalOpen(false);
                                            assignUser(StatoPosto.Referente);
                                        }} color="success"><FormattedMessage id="asReferent" /></Button>
                                    </DialogActions>
                                </Dialog>
                                <Grid container direction="row" spacing={2} sx={{ mb: 2 }}>
                                    {
                                        assignedUser === null ?
                                            (<>
                                                <Grid item>
                                                    <Button variant="contained" onClick={() => setOpenAssignUserModal(!openAssignUserModal)} startIcon={<PersonAddIcon />}>
                                                        <FormattedMessage id="assignUser" />
                                                    </Button>
                                                </Grid>
                                            </>) :
                                            (<>
                                                <TableContainer component={Paper}>
                                                    <DialogDeleteElement
                                                        open={removeDealerEntitledDialogOpen}
                                                        onClose={() => setRemoveDealerEntitledDialogOpen(false)}
                                                        onCancel={() => setRemoveDealerEntitledDialogOpen(false)}
                                                        onConfirm={() => {
                                                            setRemoveDealerEntitledDialogOpen(false)
                                                            removeAssignedUserFromPosto();
                                                        }}
                                                        entityToDeleteInfo={`${assignedUser?.nome} ${assignedUser?.cognome}` ?? ''}
                                                        customMessageIntlId="confirmDeletion"
                                                        key="removeEntitledDealer"
                                                    />
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <PurpleStyledTableCell> <FormattedMessage id="surname" /> </PurpleStyledTableCell>
                                                                <PurpleStyledTableCell> <FormattedMessage id="name" /> </PurpleStyledTableCell>
                                                                <PurpleStyledTableCell> <FormattedMessage id="birthDate" /> </PurpleStyledTableCell>
                                                                <PurpleStyledTableCell> <FormattedMessage id="holderType" /> </PurpleStyledTableCell>
                                                                <PurpleStyledTableCell> <FormattedMessage id="actions" /> </PurpleStyledTableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <AlternateTableRow key={assignedUser.id}>
                                                                <TableCell> {assignedUser.cognome} </TableCell>
                                                                <TableCell> {assignedUser.nome} </TableCell>
                                                                <TableCell> {getShortDate(assignedUser.dataNascita)} </TableCell>
                                                                <TableCell> <b><FormattedMessage id={headerLabelType} /></b> </TableCell>
                                                                <TableCell>
                                                                    <Tooltip title={<FormattedMessage id="goToUserDossiers" />} aria-label="Go to user dossiers">
                                                                        <IconButton
                                                                            component={RouterLink}
                                                                            to={`/users/dossiersList?userId=${assignedUser.id}`}>
                                                                            <IconFiles fontSize="small" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title={<FormattedMessage id="goToUser" />} aria-label="Go to user">
                                                                        <IconButton
                                                                            component={RouterLink}
                                                                            to={`/users/edit?userId=${assignedUser.id}`}>
                                                                            <ArrowForwardIcon fontSize="small" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title={<FormattedMessage id="delete" />} aria-label="Delete">
                                                                        <IconButton onClick={() => setRemoveDealerEntitledDialogOpen(true)}>
                                                                            <DeleteTwoTone fontSize="small" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title={<FormattedMessage id="makeDecuius" />} aria-label="Make decuius">
                                                                        <IconButton onClick={() => makeUserDecuius()}>
                                                                            <Coffin sx={{ width: 17 }} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </TableCell>
                                                            </AlternateTableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </>)
                                    }

                                </Grid>
                                <Collapse in={openAssignUserModal}>
                                    <Box sx={{
                                        padding: "5px",
                                        overflowY: 'auto',
                                    }}>
                                        <UsersList fromExternal={true} fromWhere="place" alreadyAssignedUsersIds={assignedUser?.id !== undefined ? [assignedUser.id] : []} assignUserToEntity={chooseUser} customAddMethod={handleAddUser} />
                                    </Box>
                                </Collapse>
                            </PostoUpdateTabPanel>
                            <PostoUpdateTabPanel value={tabIndex} index={2}>
                                <Typography variant="h3" sx={{ mb: 2 }}> <FormattedMessage id="corpse" /> </Typography>
                                {
                                    assignedDecuius === null ?
                                        (<Grid container direction="row" spacing={2} sx={{ mb: 2 }}>
                                            <Grid item>
                                                <Button variant="contained" onClick={() => setOpenAssignCorpseModal(!openAssignCorpseModal)} startIcon={<PersonAddIcon />}>
                                                    <FormattedMessage id="assignDecuius" />
                                                </Button>
                                            </Grid>
                                        </Grid>) :
                                        (<>
                                            <DialogDeleteElement
                                                open={removeDecuiusDialogOpen}
                                                onClose={() => setRemoveDecuiusDialogOpen(false)}
                                                onCancel={() => setRemoveDecuiusDialogOpen(false)}
                                                onConfirm={() => {
                                                    setRemoveDecuiusDialogOpen(false)
                                                    removeAssignedDecuiusFromPosto();
                                                }}
                                                entityToDeleteInfo={`${assignedDecuius?.nome} ${assignedDecuius?.cognome}` ?? ''}
                                                customMessageIntlId="confirmDeletion"
                                                key="removeDecuius"
                                            />
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <PurpleStyledTableCell> <FormattedMessage id="surname" /> </PurpleStyledTableCell>
                                                            <PurpleStyledTableCell> <FormattedMessage id="name" /> </PurpleStyledTableCell>
                                                            <PurpleStyledTableCell> <FormattedMessage id="birthDate" /> </PurpleStyledTableCell>
                                                            <PurpleStyledTableCell> <FormattedMessage id="deathDate" /> </PurpleStyledTableCell>
                                                            <PurpleStyledTableCell> <FormattedMessage id="actions" /> </PurpleStyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <AlternateTableRow key={assignedDecuius.id}>
                                                            <TableCell> {assignedDecuius.cognome} </TableCell>
                                                            <TableCell> {assignedDecuius.nome} </TableCell>
                                                            <TableCell> {getShortDate(assignedDecuius.dataNascita)} </TableCell>
                                                            <TableCell> {getShortDate(assignedDecuius.dataMorte)} </TableCell>
                                                            <TableCell>
                                                                <Tooltip title={<FormattedMessage id="goToDecuius" />} aria-label="Go to decuius">
                                                                    <IconButton
                                                                        component={RouterLink}
                                                                        to={`/decuius/edit?decuiusId=${assignedDecuius.id}`}>
                                                                        <ArrowForwardIcon fontSize="small" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title={<FormattedMessage id="delete" />} aria-label="Delete">
                                                                    <IconButton onClick={() => setRemoveDecuiusDialogOpen(true)}>
                                                                        <DeleteTwoTone fontSize="small" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title={<FormattedMessage id="makeBox" />} aria-label="Make box">
                                                                    <IconButton onClick={() => changeDecuiusStatus(assignedDecuius!.id!, StatoDecuius.Resti)}>
                                                                        <InboxIcon fontSize="small" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCell>
                                                        </AlternateTableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </>)
                                }
                                <Collapse in={openAssignCorpseModal}>
                                    <Box sx={{
                                        padding: "5px",
                                        overflowY: 'auto',
                                    }}>
                                        <DecuiusList fromExternal={true} assignDecuiusToEntity={assignDecuius} customAddMethod={() => handleAddDecuius(StatoDecuius.Salma)} decuiusType={StatoDecuius.Salma} onlyWithPlace={false} />
                                    </Box>
                                </Collapse>

                                <Typography variant="h3" sx={{ mt: 2, mb: 2 }}> <FormattedMessage id="boxes" /> </Typography>
                                <Grid container direction="row" spacing={2} sx={{ mb: 2 }}>
                                    <Grid item>
                                        <Button variant="contained" onClick={() => setOpenAssignBoxesModal(!openAssignBoxesModal)} startIcon={<PersonAddIcon />}>
                                            <FormattedMessage id="assignBoxes" />
                                        </Button>
                                    </Grid>
                                </Grid>

                                <Collapse in={openAssignBoxesModal}>
                                    <Box sx={{
                                        padding: "5px",
                                        overflowY: 'auto',
                                    }}>
                                        <DecuiusList fromExternal={true} assignDecuiusToEntity={assignDecuiusBox} alreadyAssignedDecuiusIds={assignedDecuiusBoxesIds} customAddMethod={() => handleAddDecuius(StatoDecuius.Resti)} decuiusType={StatoDecuius.Resti} onlyWithPlace={false} />
                                    </Box>
                                </Collapse>
                                <DialogDeleteElement
                                    open={removeDecuiusBoxDialogOpen}
                                    onClose={() => setRemoveDecuiusBoxDialogOpen(false)}
                                    onCancel={() => setRemoveDecuiusBoxDialogOpen(false)}
                                    onConfirm={() => {
                                        setRemoveDecuiusBoxDialogOpen(false)
                                        removeAssignedDecuiusBoxFromPosto(entityToRemoveData!.id);
                                    }}
                                    entityToDeleteInfo={entityToRemoveData?.name ?? ''}
                                    customMessageIntlId="confirmDeletion"
                                    key="removeDecuiusBox"
                                />
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <PurpleStyledTableCell> <FormattedMessage id="surname" /> </PurpleStyledTableCell>
                                                <PurpleStyledTableCell> <FormattedMessage id="name" /> </PurpleStyledTableCell>
                                                <PurpleStyledTableCell> <FormattedMessage id="birthDate" /> </PurpleStyledTableCell>
                                                <PurpleStyledTableCell> <FormattedMessage id="deathDate" /> </PurpleStyledTableCell>
                                                <PurpleStyledTableCell> <FormattedMessage id="actions" /> </PurpleStyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                isBoxesLoading ?
                                                    <TableRowsLoader rowsNum={4} colsNum={5} /> :
                                                    assignedDecuiusBoxes && assignedDecuiusBoxes.map((assignedDecuiusBox) => (
                                                        <AlternateTableRow key={assignedDecuiusBox.id}>
                                                            <TableCell> {assignedDecuiusBox.cognome} </TableCell>
                                                            <TableCell> {assignedDecuiusBox.nome} </TableCell>
                                                            <TableCell> {getShortDate(assignedDecuiusBox.dataNascita)} </TableCell>
                                                            <TableCell> {getShortDate(assignedDecuiusBox.dataMorte)} </TableCell>
                                                            <TableCell>
                                                                <Tooltip title={<FormattedMessage id="goToDecuius" />} aria-label="Go to decuius">
                                                                    <IconButton
                                                                        component={RouterLink}
                                                                        to={`/decuius/edit?decuiusId=${assignedDecuiusBox.id}`}>
                                                                        <ArrowForwardIcon fontSize="small" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title={<FormattedMessage id="delete" />} aria-label="Delete">
                                                                    <IconButton onClick={() => {
                                                                        setEntityToRemoveData({
                                                                            id: assignedDecuiusBox.id!,
                                                                            name: `${assignedDecuiusBox.nome} ${assignedDecuiusBox.cognome}`
                                                                        });
                                                                        setRemoveDecuiusBoxDialogOpen(true);
                                                                    }}>
                                                                        <DeleteTwoTone fontSize="small" />
                                                                    </IconButton>
                                                                </Tooltip>

                                                                {
                                                                    assignedDecuius === null && (
                                                                        <Tooltip title={<FormattedMessage id="makeCorpse" />} aria-label="Make corpse">
                                                                            <IconButton onClick={() => changeDecuiusStatus(assignedDecuiusBox.id!, StatoDecuius.Salma)}>
                                                                                <Coffin sx={{ width: 17 }} />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    )
                                                                }
                                                            </TableCell>
                                                        </AlternateTableRow>
                                                    ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </PostoUpdateTabPanel>
                            <PostoUpdateTabPanel value={tabIndex} index={3}>
                                <Grid
                                    container
                                    direction="row"
                                >
                                    <Grid item lg={4}>
                                        <Button component="label" variant="contained" startIcon={<AttachFileIcon />}>
                                            <FormattedMessage id="uploadFile" />
                                            <VisuallyHiddenInput type="file" onChange={handleUploadFile} />
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    direction="column"
                                >
                                    <DialogDeleteElement
                                        open={removeAttachmentDialogOpen}
                                        onClose={() => setRemoveAttachmentDialogOpen(false)}
                                        onCancel={() => setRemoveAttachmentDialogOpen(false)}
                                        onConfirm={() => {
                                            setRemoveAttachmentDialogOpen(false)
                                            handleDeleteFile(entityToRemoveData!.id)
                                        }}
                                        entityToDeleteInfo={entityToRemoveData?.name ?? ''}
                                        customMessageIntlId="confirmDeletion"
                                        key="removeAttachment"
                                    />
                                    {files && files?.map((file) => (
                                        <Grid container direction="row" sx={{ mt: 2 }} columnSpacing={2}>
                                            <Grid item
                                                xs={1}
                                                md={1}
                                                xl={1}
                                            >
                                                <Tooltip title={<FormattedMessage id="rename" />} aria-label="rename">
                                                    {
                                                        fileInEditId !== file.id ?
                                                            <IconButton edge="end" onClick={() => { setFileInEditId(file.id!); setNewFileName(file.nomeFile) }}>
                                                                <EditIcon fontSize="small" />
                                                            </IconButton> :
                                                            <>
                                                                <IconButton edge="end" onClick={handleCloseEditName} sx={{ mr: 1 }}>
                                                                    <CloseIcon fontSize="small" />
                                                                </IconButton>
                                                                <IconButton edge="end" onClick={handleConfirmEditName}>
                                                                    <CheckIcon fontSize="small" />
                                                                </IconButton>
                                                            </>
                                                    }
                                                </Tooltip>
                                            </Grid>
                                            <Grid item
                                                xs={4}
                                                md={4}
                                                xl={4}
                                            >
                                                <Tooltip title={fileInEditId !== file.id ? file.nomeFile : newFileName}>
                                                    <TextField
                                                        size="small"
                                                        value={fileInEditId !== file.id ? file.nomeFile : newFileName}
                                                        disabled={fileInEditId !== file.id}
                                                        onChange={(e) => { setNewFileName(e.target.value) }}
                                                        fullWidth
                                                    />
                                                </Tooltip>
                                            </Grid>
                                            <Grid item
                                                xs={4}
                                                md={4}
                                                xl={4}
                                            >
                                                <Tooltip title={<FormattedMessage id="delete" />} aria-label="Delete" sx={{ mr: 1 }}>
                                                    <IconButton edge="end" onClick={() => {
                                                        setEntityToRemoveData({
                                                            id: file.id!,
                                                            name: file.nomeFile
                                                        });
                                                        setRemoveAttachmentDialogOpen(true);
                                                    }}>
                                                        <DeleteTwoTone />
                                                    </IconButton>
                                                </Tooltip>

                                                <Tooltip title={<FormattedMessage id="download" />} aria-label="download">
                                                    <IconButton edge="end" onClick={() => downloadAllegato(file.id!, file.nomeFile!)}>
                                                        <DownloadIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </PostoUpdateTabPanel>
                        </CardContent >
                    )
            }
        </Card >
    )
};

export default PostoUpdate;
